define("ember-get-config/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "modulePrefix": "@ritual/checkout",
    "environment": "production",
    "rootURL": "/",
    "locationType": "auto",
    "i18n": {
      "defaultLocale": "en-us"
    },
    "EmberENV": {
      "FEATURES": {},
      "EXTEND_PROTOTYPES": {
        "Date": false
      },
      "_JQUERY_INTEGRATION": false
    },
    "activeEnv": "production",
    "host": "https://api.ritual.com",
    "namespace": "api/v1",
    "homeUrl": "https://ritual.com",
    "accountManagerUrl": "https://account.ritual.com",
    "cloudflareHost": "https://ritual.com",
    "stripe": {
      "publishableKey": "pk_live_WMeJN8QujSCDgIcJK5E5XENB",
      "lazyLoad": true
    },
    "braintree": {
      "authorization": "production_rhd6vbfv_stjhqx2btgxvpc9s",
      "environment": "production"
    },
    "contentful": {
      "space": "uuc5ok478nyh",
      "accessToken": "1047e0afc0a608f85e454733915d643fb6b611f1bd59039fbd09fafcb5137e4a",
      "previewAccessToken": "652e264acd11929b4ffebf3ea137e484a0d44e0fe57c30696c665dd210e972c8",
      "usePreviewApi": false,
      "environment": "master"
    },
    "mapbox": {
      "accessToken": "pk.eyJ1Ijoicml0dWFsIiwiYSI6ImNrYjZxOTh5MDAxM3QyeHNkNmpvZTE4emEifQ.56izhLp4KU86qR_y7J6Fpg"
    },
    "castlePublishableKey": "pk_gTPsEZK6PgPkpmEsxGT9b6DVZy9aAdrj",
    "launchDarklyClientId": "5b526140f7db352ec53debcb",
    "segmentWriteKey": "ggPRI3y1YeiyKA4HFVpyVs0bpXAvpCPT",
    "homeSegmentWriteKey": "jBKkKOGmXz9UdedZorYIp3eZemJ18P5f",
    "accountSegmentWriteKey": "i7w0QiJzs86wBaM1h1xDI26GELQtgKGi",
    "smartyStreetsWebskiteKey": "19369788519939256",
    "appleRedirectUri": "https://checkout.ritual.com/callback",
    "appleClientId": "com.ritual.prod",
    "googleClientId": "175608687555-k2i1dejkfl34t4qocrf3d169q7bst3h5.apps.googleusercontent.com",
    "APP": {
      "imgix": {
        "source": "ritual.imgix.net",
        "debug": false
      },
      "validatorDefaultLocale": "en",
      "name": "@ritual/checkout",
      "version": "2.0.0"
    },
    "addEventClientId": "abENgMPamzWQyeqzVmhf55612",
    "legacyPlansCutoffDate": "2024-03-30T00:00:00.000Z",
    "ember-simple-auth": {
      "authenticationRoute": ""
    },
    "cookieDomain": ".ritual.com",
    "currentRevision": "093528ebae8e3f7b61bf128941196cb54dc40bc5",
    "bugsnag": {
      "apiKey": "c71b0ad1a8b1f6123046838097dd5fd3",
      "releaseStage": "production",
      "appVersion": "v1592"
    },
    "ember-cli-mirage": {
      "usingProxy": false,
      "useDefaultPassthroughs": true
    },
    "contentSecurityPolicy": {
      "style-src": "'self' 'unsafe-inline' 'unsave-eval'"
    },
    "exportApplicationGlobal": false,
    "ember-faker": {},
    "ember-load": {
      "loadingIndicatorClass": "ember-load-indicator"
    }
  };
});